<template>
  <div class="mx-auto container container--fluid">
    <v-toolbar flat dense color="white">
      <v-toolbar-title>Field Config</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-app id="inspire" class>
      <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
        <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>

      <template v-if="load == true">
        <v-data-table
          :headers="headers"
          :items="field_config_list"
          sort-by="fieldtype"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer> <span style="width: 20px"></span>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="indigo darken-4" dark class="mb-2" v-on="on"
                    >New Config</v-btn
                  >
                </template>
                <v-card>
                  <v-card-title>
                    <!-- <span class="headline">{{ formTitle }}</span> -->
                    <v-row justify="space-between">
                      <span class="headline">{{ formTitle }}</span>
                      <v-btn icon dark @click="dialog = false">
                        <v-icon color="black">mdi-close</v-icon>
                      </v-btn>
                    </v-row>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-if="editedIndex == -1"
                        >
                          <v-autocomplete
                            :items="field_type_list"
                            v-model="editedItem.fieldtype"
                            label="Select Field Type"
                            :rules="[(v) => !!v || 'required']"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" v-if="editedIndex != -1"
                          >Field Type
                          <v-select
                            dense
                            v-model="editedItem.fieldtype"
                            :items="field_type_list"
                            item-value="id"
                            persistent-hint
                            :error="errorMsg.fieldtype ? true : false"
                            :error-messages="
                              errorMsg.fieldtype ? 'Required' : ''
                            "
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-if="editedIndex == -1"
                        >
                          <v-autocomplete
                            :items="field_list"
                            v-model="editedItem.field"
                            label="Select Field"
                            :rules="[(v) => !!v || 'required']"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" v-if="editedIndex != -1"
                          >Field Name
                          <v-select
                            dense
                            v-model="editedItem.field"
                            :items="field_list"
                            item-value="id"
                            persistent-hint
                            :error="errorMsg.field ? true : false"
                            :error-messages="errorMsg.field ? 'Required' : ''"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12">
                          <v-checkbox
                            dense
                            v-model="editedItem.iseditable"
                            color="success"
                            label="Is-Editable?"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                          <v-checkbox
                            dense
                            v-model="editedItem.required"
                            color="success"
                            label="Required?"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                          <v-checkbox
                            dense
                            v-model="editedItem.isactive"
                            color="success"
                            label="Active?"
                          ></v-checkbox>
                        </v-col>
                        <v-col>
                          <input
                            type="hidden"
                            v-model="editedItem.id"
                            name="id"
                          />
                          <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close"
                      >Cancel</v-btn
                    >
                    <v-btn color="primary darken-1" @click="save">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.iseditable="{ item }">
            <span v-if="item.iseditable">
              <v-icon size="15" color="success" title="yes">YES</v-icon>
            </span>
            <span v-else>
              <v-icon size="15" color="error" title="no">NO</v-icon>
            </span>
          </template>

          <template v-slot:item.required="{ item }">
            <span v-if="item.required">
              <v-icon size="15" color="success" title="yes">YES</v-icon>
            </span>
            <span v-else>
              <v-icon size="15" color="error" title="no">NO</v-icon>
            </span>
          </template>

          <!-- <template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive">
                        <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                        <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                        </span>
                    </template> -->
          <template v-slot:item.isactive="{ item }">
            <v-switch
              v-model="item.isactive"
              @click="activeswitch(item)"
            ></v-switch>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-avatar style size="25" class="edit-avatar">
                  <v-icon
                    class="edit-v-icon mr-2"
                    @click="editItem(item)"
                    title="edit"
                    style
                    >edit</v-icon
                  >
                </v-avatar>
                <v-btn text>
                  <v-icon color="red" @click="deletefunc(item.id)">
                    mdi-delete</v-icon
                  ></v-btn
                >
              </template>
              <span>edit</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    fieldsforsearch: [
      "Gender",
      "Standred",
      "First name",
      "Last name",
      "Middle name",
      "Birth Place",
      "Date of Birth",
      "Family income",
      "Prizes",
      "Domacile",
      "Category",
    ],
    errorMsg: {
      FieldType: false,
      fieldName: false,
    },
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    load: false,
    dialog: false,
    field_config_list: null,
    field_type_list: null,
    field_list: null,
    headers: [
      {
        text: "Field Type",
        value: "fieldtype",
      },
      {
        text: "Field Name",
        value: "field",
      },
      {
        text: "Is-Editable?",
        value: "iseditable",
      },
      {
        text: "Is-Compulsory?",
        value: "required",
      },
      {
        text: "Active",
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],

    editedIndex: -1,
    editedItem: {
      id: "",
      fieldtype: "",
      field: "",
      iseditable: true,
      isactive: true,
      required: true,
    },
    defaultItem: {
      id: "",
      fieldtype: "",
      field: "",
      iseditable: true,
      isactive: true,
      required: true,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Config" : "Edit Config";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  mounted() {
    this.onLoad();
  },

  methods: {
    initializeErrorMsgFalse() {
      this.errorMsg.FieldType = false;
      this.errorMsg.fieldName = false;
    },

    onLoad() {
      this.overlay = true;
      axios

        .post("/AdminMaster/addFieldConfig")

        .then((res) => {
          if (res.data.msg == "200") {
            //window.alert(res.data.msg)

            if ((this.load = true)) {
              this.overlay = false;
              this.field_config_list = res.data.field_config_list;
              this.field_type_list = res.data.field_type_list;
              this.field_list = res.data.field_list;
              // this.load = true;
            }
          } else {
            window.console.log(res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          //window.console.log(error);
        })
        .finally(() => {
          "use strict";
          var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    editItem(item) {
      this.editedIndex = this.field_config_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.initializeErrorMsgFalse();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        if (this.isValidated()) {
          //  console.log("edit.." + this.editedItem);

          axios
            .post("/AdminMaster/editFieldConfig", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                Object.assign(
                  this.field_config_list[this.editedIndex],
                  this.editedItem
                );
                this.showSnackbar(
                  "#4caf50",
                  "Field Config Updated Successfully..."
                ); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                this.showSnackbar(
                  "#b71c1c",
                  " Field Config Already Present!!!"
                ); // show snackbar on error
              }
            })
            .catch((error) => {
              window.console.log(error);
            });

          this.close();
        }
      } else {
        if (this.isValidated()) {
          axios
            .post("/AdminMaster/saveFieldConfig", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                this.field_config_list.push(this.editedItem);
                this.showSnackbar(
                  "#4caf50",
                  " Field Config Added Successfully..."
                ); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                this.showSnackbar(
                  "#b71c1c",
                  " Field Config Already Present!!!"
                ); // show snackbar on error
              }
              this.onLoad();
            })
            .catch((error) => {
              window.console.log(error);
            });

          this.close();
        }
      }
    }, // end of save()

    isValidated() {
      if (this.editedItem.fieldtype && this.editedItem.field) {
        return true;
      } else {
        if (!this.editedItem.fieldtype) {
          this.errorMsg.FieldType = true;
        }
        if (!this.editedItem.field) {
          this.errorMsg.fieldName = true;
        }
        return false;
      }
    },
    deletefunc(item) {
      const data = {
        deleteitem: item,
      };
      axios
        .post("/AdminMaster/deleteFieldConfig", data)
        .then((res) => {
          //console.log("a")
          if (res.data.msg == "200") {
            this.showSnackbar("#4caf50", "Deleted Successfully...");
            //this.onLoad()
          } else {
            //error
            this.showSnackbar("#b71c1c", res.data.msg);
          }
          this.onLoad();
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
    activeswitch(item) {
      //console.log("abc");

      const data = {
        deleteitem: item.id,
      };
      //console.log(item.id);

      axios
        .post("/AdminMaster/activeFieldConfig", data)
        .then((res) => {
          //   console.log("res.data");
          //   console.log(res.data);
          if (res.data.msg == "200") {
            // console.log("res.data");
            // console.log(res.data);
            // console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            // console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>
